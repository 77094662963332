'use client';
import React, { createContext, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { toast } from 'react-hot-toast';
import { fetchData, addToCartWish, deleteFromCartWish } from '../cart/api';
import { useRouter } from 'next/navigation';
import { useAuth } from '../AuthContext';
import getToken from '@/hook/getToken';
export const WishListContext = createContext();
export const WishListProvider = ({ children }) => {
    const router = useRouter()
    const [wishList, setWishList] = useState([]);
    const { isSignInOpen, setIsSignInOpen, setIsSignUpOpen } = useAuth()
    const fetchWishList = useCallback(async () => {
        try {
            const response = await fetchData('/v1/wishlist/get-wishlist/b2c?page=1&limit=500');
            const data = await response.json();


            const wishList = (data?.wishlist_data || []).map(item => ({
                id: item?.product_id || null,
                type: item?.type || null
            }));


            setWishList(wishList);
        } catch (error) {
            console.log('Error fetching cart items:', error);
        }
    }, []);

    useEffect(() => {
        const token = getToken();
        if (!token) return;
        fetchWishList();
    }, [fetchWishList, isSignInOpen]);

    const addWishList = useCallback(async (productId, type) => {
        const body = {
            product_id: productId,
            type: type
        };

        try {
            const response = await addToCartWish('/v1/wishlist/create-wishlist', body);
            if (response.status === 200) {
                fetchWishList()
            }
            const data = await response.json();
            if (data.message === 'Unauthorized') {
                setIsSignInOpen(true)
                return data.message;
            }
            if (data.registration_complete === false) {
                setIsSignUpOpen(true)
                // router.push('/user/account?completesignup=true')
            }

        } catch (error) {
            console.log('An error occurred:', error);
        }
    }, []);

    const deleteWishList = useCallback(async (productId) => {
        const url = `/v1/wishlist/delete-wishlist/b2c/${productId}`;
        try {
            const response = await deleteFromCartWish(url);
            if (response.message === 'Unauthorized') {
                setIsSignInOpen(true)
            }
            if (response.ok) {
                setWishList((prevValue) => prevValue.filter(item => item.id !== productId));
                toast.success(`Succeccfully remove from your wishlist`);
            }
            const data = await response.json();

        } catch (error) {
            console.log('An error occurred:', error);
        }
    }, []);

    // Extract values from context outside of useMemo
    const contextValue = { wishList, addWishList, deleteWishList };

    // Memoize the context value to prevent unnecessary re-renders of consumers
    const memoizedValue = useMemo(() => contextValue, [contextValue]);

    return (
        <WishListContext.Provider value={memoizedValue}>
            {children}
        </WishListContext.Provider>
    );
};

export const useWishList = () => {
    // Use the context directly outside of useMemo or useEffect
    const contextValue = useContext(WishListContext);

    // Memoize the return value of the custom hook to prevent unnecessary re-renders of components using this hook
    return useMemo(() => contextValue, [contextValue]);
};
