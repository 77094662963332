"use client";
import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
  use,
} from "react";
import { useAuth } from "../AuthContext";
import { toast } from "react-hot-toast";
import {
  fetchData,
  addToCartWish,
  deleteFromCartWish,
  editCartQuantity,
} from "./api";
import { useRouter } from "next/navigation";
import getToken from "@/hook/getToken";
import BASE_URL from "@/components/constants";

export const CartContext = createContext();

// fbq('track', 'AddToCart', {
//   content_ids: ['123'], // 'REQUIRED': array of product IDs
//   content_type: 'product', // RECOMMENDED: Either product or product_group based on the content_ids or contents being passed.
// });

export const CartProvider = ({ children }) => {
  const { isSignInOpen, setIsSignInOpen, setIsSignUpOpen } = useAuth();
  const router = useRouter();
  const [cart, setCart] = useState();
  const [buyItems, setBuyItems] = useState();
  const [isBuyNow, setIsBuyNow] = useState(false);
  const [couponId, setCouponId] = useState();
  const [isMutating, setIsMutating] = useState(false);

  const [orderResData, setOrderResData] = useState();
  // apiurl for fetching cart data
  const apiUrl = couponId
    ? `/v1/cart/get-page/b2c?page=1&limit=500&coupon_id=${couponId}`
    : "/v1/cart/get-page/b2c?page=1&limit=500";
  useEffect(() => {
    const token = getToken();
    if (!token) return;
    fetchCartItems(apiUrl);
  }, [isSignInOpen, couponId]);

  useEffect(() => {
    const data = localStorage.getItem("orderData");
    if (!data) return;
    setOrderResData(JSON.parse(data));
  }, []);
  const fetchCartItems = useCallback(

    async (apiUrl) => {
      const token = getToken();
      if (!token) return;
      try {
        const couponid = couponId;
        const response = await fetchData(apiUrl);
        const data = await response.json();

        if (response.ok) {
          setCart(data);

        }
      } catch (error) {
        console.log("Error fetching cart items:", error);
      }
    },
    [couponId]
  );

  const addToCart = async (productId, orderQty) => {
    const body = {
      product_id: productId,
      order_qty: orderQty,
    };
    try {
      const response = await addToCartWish("/v1/cart/add", body);
      const data = await response.json();
      if (response.ok) {
        fetchCartItems("/v1/cart/get-page/b2c?page=1&limit=500");
        toast.success(data.message);
      }
      if (data.message === "Unauthorized") {
        setIsSignInOpen(true);
      }
      return data;
    } catch (error) {

    }
  };

  const removeFromCart = async (cartId) => {
    const url = `/v1/cart/remove/${cartId}`;
    try {
      const response = await deleteFromCartWish(url);
      if (response.message === "Unauthorized") {
        setIsSignInOpen(true);
      }
      if (response.ok) {
        fetchCartItems(apiUrl);
        fetchBuyNow();
        toast.success(`Succeccfully remove from your cart.`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log("An error occurred:", error);
    }
  };

  const changeQty = async (id, qty) => {
    setIsMutating(true);
    const url = `/v1/cart/edit/${id}`;
    const body = {
      order_qty: qty,
    };

    try {
      const response = await editCartQuantity(url, body);
      if (response.message === "Unauthorized") {
        setIsSignInOpen(true);
      }
      const resData = await response.json();
      if (response.ok) {
        fetchCartItems(apiUrl);
        fetchBuyNow();
        setIsMutating(false);
        toast.success(resData.message);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      setIsMutating(false);
    }
  };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };

      document.body.appendChild(script);
    });
  };

  const initPayment = async (data, id, paymentMethod, buyNow, prefill) => {
    const res = await initializeRazorpay();

    if (!res) {
      alert("Razorpay SDK Failed to load");
      return;
    }
    const options = {
      key: process.env.NEXT_PUBLIC_RAZORPAY_KEY,
      amount: data.amount,
      currency: data.currency,
      description: "Test Transaction",
      order_id: data.id,
      prefill: prefill,
      handler: async (razorpayResponse) => {
        try {
          const verifyUrl = BASE_URL + "/v1/orders/payment-verify-b2c";
          const requestBody = {
            billing_address_id: id,
            buy_now: buyNow,
            shipping_address_id: id,
          };

          // Conditionally add couponId to orderData if it's not undefined
          if (couponId !== undefined) {
            requestBody.coupon_id = couponId;
          }
          const token = getToken();
          delete razorpayResponse.status_code;
          const combinedData = {
            ...razorpayResponse,
            ...requestBody,
          };

          const headers = {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          };

          const requestOptions = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(combinedData),
          };

          const response = await fetch(verifyUrl, requestOptions);
          const { order_details } = await response.json();
          setOrderResData(order_details);
          if (order_details) {
            localStorage.setItem("orderData", JSON.stringify(order_details));
          }
          if (response.ok) {
            fetchCartItems('/v1/cart/get-page/b2c?page=1&limit=500');
            router.push("/success");
          }

        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#000000",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };



  //data for buy now option
  const buyUrl = couponId ? `/v1/cart/get-page/b2c/now?coupon_id=${couponId}` : `/v1/cart/get-page/b2c/now`;
  const fetchBuyNow = async () => {
    const response = await fetchData(buyUrl);
    const data = await response.json();

    if (response.ok) {
      try {
        setBuyItems(data);
      } catch (error) {
        console.log("An error occurred:", error);
      }
    }
  };
  useEffect(() => {
    const token = getToken();
    const sessionId = localStorage.getItem('sessionId');
    if (!token) return;
    if (sessionId) return;
    fetchBuyNow();
  }, [isBuyNow, couponId]);

  //quick buy withour login
  const [isQuickBuy, setIsQuickBuy] = useState(false);
  useEffect(() => {
    const sessionId = localStorage.getItem('sessionId');
    if (!sessionId) return;

    const fetchQuickBuyItems = async () => {
      try {
        const response = await fetch(`${BASE_URL}/v1/cart/get-quick-buy?session_id=${sessionId}`);
        const data = await response.json();
        console.log('quick buy items', data, 'response', response, 'sessionId', sessionId);
        if (response.ok) {
          setBuyItems(data);
        }
      } catch (error) {
        console.error('Failed to fetch quick buy items', error);
      }
    };

    fetchQuickBuyItems();
  }, [isQuickBuy]);


  const handleOrderPlace = async (id, paymentMethod, buyNow, token) => {


    try {
      let apiUrl = BASE_URL + "/v1/orders/place-order";

      const orderData = {
        shipping_address_id: id,
        billing_address_id: id,
        buy_now: buyNow,
      };

      // Conditionally add couponId to orderData if it's not undefined
      if (couponId !== undefined) {
        orderData.coupon_id = couponId;
      }

      // Conditionally set the API endpoint based on paymentMethod
      if (paymentMethod === "cash") {
        apiUrl = BASE_URL + "/v1/orders/place-order";
        orderData.payment_method = paymentMethod;
      } else if (paymentMethod === "online") {
        apiUrl = BASE_URL + "/v1/orders/payment-order-b2c";
        // Don't include paymentMethod for 'online'
      }

      // Set up the authorization header with the token
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(orderData),
      };

      const response = await fetch(apiUrl, requestOptions);
      const responseData = await response.json();
      if (response.ok) {

        initPayment(responseData.data, id, paymentMethod, buyNow, responseData.prefill);
        setOrderResData(responseData?.order_details);
        if (responseData?.order_details) {
          localStorage.setItem("orderData", JSON.stringify(responseData?.order_details));
        }
        if (paymentMethod == "cash") {

          fetchCartItems(apiUrl);
          toast.success(
            "Congratulations! Your order has been placed successfully."
          );
          router.push("/success");

        }
      } else {
        // Handle the error when the order placement fails
        toast.error(responseData.message)
        console.error("Failed to place the order:", responseData);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    } finally {
      localStorage.removeItem('sessionId');
    }
  };

  // deleting quick buy item
  const deleteQuickbuyItem = async (productId, sessionId) => {

    const url = `${BASE_URL}/v1/cart/remove-quick-buy?session_id=${sessionId}`;
    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      if (response.ok) {
        toast.success(`Succeccfully remove from your cart.`);
        setBuyItems();
      }
    } catch (error) {
      console.log("An error occurred:", error);
    }
  }
  // quantity change option
  const quickBuyQuantityChange = async (sessionId, qty) => {
    setIsMutating(true);
    const url = `${BASE_URL}/v1/cart/edit-quick-buy-qty?session_id=${sessionId}`;
    const body = {
      order_qty: qty,
    };

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.ok) {
        toast.success("Quantity updated successfully.");
        setIsQuickBuy(pre => !pre);
      }
    } catch (error) {
      console.log("An error occurred:", error);
    } finally {
      setIsMutating(false);
    }
  };

  // Memoize the context value using useMemo
  const memoizedValue = useMemo(() => {
    return {
      cart,
      setCart,
      setBuyItems,
      addToCart,
      changeQty,
      removeFromCart,
      handleOrderPlace,
      isMutating,
      setCouponId,
      isBuyNow,
      setIsBuyNow,
      buyItems,
      orderResData,
      deleteQuickbuyItem,
      quickBuyQuantityChange
    };
  }, [
    cart,
    setCart,
    setBuyItems,
    isMutating,
    addToCart,
    changeQty,
    removeFromCart,
    handleOrderPlace,
    setCouponId,
    isBuyNow,
    setIsBuyNow,
    buyItems,
    setBuyItems,
    orderResData,
    deleteQuickbuyItem,
    quickBuyQuantityChange
  ]);

  return (
    <CartContext.Provider value={memoizedValue}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
