'use client';
import React, { createContext, useState, useContext, useMemo, } from 'react';



export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const [isSignInOpen, setIsSignInOpen] = useState(false);
    const [isSignUpOpen, setIsSignUpOpen] = useState(false);
    const [isGuestLogin, setIsGuestLogin] = useState(false);


    // Extract values from context outside of useMemo
    const contextValue = { isSignInOpen, setIsSignInOpen, isSignUpOpen, setIsSignUpOpen, isGuestLogin, setIsGuestLogin };

    // Memoize the context value to prevent unnecessary re-renders of consumers
    const memoizedValue = useMemo(() => contextValue, [contextValue]);

    return (
        <AuthContext.Provider value={memoizedValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    // Use the context directly outside of useMemo or useEffect
    const contextValue = useContext(AuthContext);

    // Memoize the return value of the custom hook to prevent unnecessary re-renders of components using this hook
    return useMemo(() => contextValue, [contextValue]);
};
