import BASE_URL from "@/components/constants";
import getToken from "@/hook/getToken";

export const fetchData = async (url) => {
    const token = getToken()
    
    try {
        const response = await fetch(`${BASE_URL}${url}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const addToCartWish = async (url, body) => {
    const token = getToken()
   
    try {
        const response = await fetch(`${BASE_URL}${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body)
        });
        
        return response;
    } catch (error) {
        console.error('Error adding cart wishlist:', error);
        throw error;
    }
}
export const deleteFromCartWish = async (url) => {
    const token = getToken()
    try {
        const response = await fetch(`${BASE_URL}${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });
        return response;
    } catch (error) {
        console.error('Error adding cart wishlist:', error);
        throw error;
    }
}
export const editCartQuantity = async (url, body) => {
    const token = getToken()
    try {
        const response = await fetch(`${BASE_URL}${url}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(body)
        });
        return response;
    } catch (error) {
        console.error('Error adding cart wishlist:', error);
        throw error;
    }
}